import React from 'react'
import "./Footer.css"
import FacebookIcon from "../ASSETS/Facebook.svg"
import TwitterIcon from "../ASSETS/Twitter.svg"
import InstagramIcon from "../ASSETS/Instagram.svg"
import {Link} from 'react-router-dom'

function Footer() {
	return (
	  <div className="Footer">

		<footer>
			<div className="sozial-container">
					<button className="facebookBut" onClick={() => window.open('https://www.facebook.com/HONKInternational/', '_blank')}>
						<img src={FacebookIcon} alt="Facebook"></img>
					</button>
					<button className="twitterkBut" onClick={() => window.open('https://twitter.com/HONKInt', '_blank')}>
						<img src={TwitterIcon} alt="Twitter"></img>
					</button>
					<button className="instagramBut" onClick={() => window.open('https://www.instagram.com/HONKInternational/', '_blank')}>
						<img src={InstagramIcon} alt="Instagram"></img>
					</button>
			</div>
			
			<div className="footer-links">
				<Link className="links" to={"/contact"}>CONTACT</Link>
				<Link className="links" to={"/datenschutz"}>PRIVACY DISCLAIMER</Link>
				<Link className="links" to={"/impressum"}>LEGAL NOTICE</Link> 
			</div>
		</footer>
	  </div>
	);
  }
  
  export default Footer;
  