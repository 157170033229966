import React, {useEffect} from 'react'
import "./Contact.css"
import Header from "../PUBLIC/Header"
import Footer from "../PUBLIC/Footer"


function Contact() {

	useEffect(() => {
		document.title = "HONK International - Contact"
	}, [])

	return (
	<div className="Contact">
		<Header title="CONTACT"/>
		<div className="content">
			<a href="mailto:original@honk.international">ORIGINAL@HONK.INTERNATIONAL</a>
		</div>
		<Footer />
	</div>
	);
  }
  
  export default Contact;
  