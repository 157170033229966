import React, {useEffect} from 'react'
import { Redirect } from "react-router-dom";
//import "./Contact.css"
import Header from "../PUBLIC/Header"
import {Route} from 'react-router-dom'


function WidgetWatch() {

	useEffect(() => {
		window.location = "https://apps.apple.com/us/app/widgetwatch-clock-widgets/id1535214658"
	}, [])

	return (
	<div className="WidgetWatch">
	</div>
	);
  }
  
  export default WidgetWatch;