import React from 'react'
import "./Header.css"
import {Link} from 'react-router-dom'

function Header(props) {
	return (
	  <div className="Header">
			<Link className="link" to={"/"}>HONK <span>INTERNATIONAL</span></Link>
			<h1>{props.title}</h1>
	  </div>
	);
  }
  
  export default Header;
  